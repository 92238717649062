/*
| ============================================
| This File is for common validation's helpers
| ============================================
*/

import _isEmpty from 'underscore/cjs/isEmpty.js'
import _uniq from 'underscore/cjs/uniq.js'

import dayjs from 'dayjs'
import { COUNTRIES } from '~/constants/country.constants'
import { STATES } from '~/constants/states.constants'
import { SUPPORTED_CREDIT_CARDS } from '~/constants/creditcard.constants'

/*
 * Validator helper for email validation check(s)
 * @param email
 * @returns {boolean}
 */
const emailValidator = email => {
  if (!email) {
    return false
  }

  let result = true
  const emailSplit = email.split('@')
  // eslint-disable-next-line
  const pattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  // email regex
  if (!pattern.test(email)) {
    result = false
  } else if (
    email.indexOf('..') > -1 ||
    email.indexOf('.') === 0 ||
    email.indexOf('.@') > -1 ||
    email.indexOf('.') === -1 ||
    email.split('@')[1].indexOf('.') === -1
  ) {
    result = false
  } else if (
    emailSplit[0].length < 2 ||
    emailSplit[1].split('.')[0].length < 2 ||
    emailSplit[1].split('.')[1].length < 2
  ) {
    result = false
  }
  return result
}

/*
 * Text validation check(s)
 * tmp : Currently added to check if text exists or not, we can change the rule if needed later on.
 * @param string
 * @returns {boolean}
 */
const textValidator = string => {
  if (!string) {
    return
  }

  const currentText = string.trim()
  return !!currentText
}

/*
 * Password validation check(s)
 * @param passwordString
 * @returns {boolean}
 */
const passwordValidator = (passwordString, pattern = null) => {
  let result = true
  // eslint-disable-next-line
  pattern = pattern ?? /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:!.?\'()\[\]-]{8,}$/
  if (passwordString) {
    passwordString = passwordString.replace(/ +/g, '')
    if (!pattern.test(passwordString)) {
      result = false
    }
  } else {
    result = false
  }
  return result
}

/*
 * number validation check(s)
 * @param numberString
 * @returns {boolean}
 */
const numberValidator = numberString => {
  let result = true
  const pattern = /^[0-9]*$/
  if (numberString) {
    if (!pattern.test(numberString)) {
      result = false
    }
  } else {
    result = false
  }
  return result
}

/*
 * Phone number validation check(s)
 * @param phoneNumber
 * @returns {boolean}
 */
const phoneNumberValidator = (phoneNumber, withAuAreaCode = false) => {
  const pattern = withAuAreaCode ? /^(2|3|4|5|7|8){1}[0-9]{8}$/ : /^0(2|3|4|5|7|8){1}[0-9]{8}$/

  if (!pattern.test(phoneNumber)) {
    return false
  }

  return true
}

/*
 * Post code validation check(s)
 * @param postcode
 * @param geoDates
 * @returns {boolean}
 */
const postCodeValidator = (postcode, geoDates = []) => {
  const postcodeSelected = geoDates.filter(geoData => {
    return geoData[2] === postcode
  })
  return !!postcodeSelected
}

/*
 * Vue array Object Prop validation
 * Checks if there are required keys that are missing in an array of objects
 * @param arr {array}
 * @param existingKeys {array}
 * @returns {boolean}
 */
const arrayObjectPropValidator = (arr, requiredKeys) => {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    const itemKeys = Object.keys(item)
    const missingKeys = requiredKeys.filter(e => !itemKeys.includes(e))

    if (missingKeys.length > 0) {
      // eslint-disable-next-line no-console
      console.log(`${missingKeys.join(', ')} are required.`)
      return false
    }
  }

  return true
}

/*
 * Object Prop validation
 * Checks if the object has the required keys,
 * @param obj {array}
 * @param keys {array}
 * @returns {boolean}
 */
const objectKeysValidator = (obj, keys) => {
  const objKeys = Object.keys(obj)

  for (let i = 0; i < objKeys.length; i++) {
    const currKey = objKeys[i]

    if (!keys.includes(currKey)) {
      // eslint-disable-next-line no-console
      console.error(currKey)
      return false
    }
  }

  return true
}

/*
 * check whether string is valid url
 * @param urlString {String}
 * @returns {true|false}
 */
const isValidUrl = urlString => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' +
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
  '((\\d{1,3}\\.){3}\\d{1,3}))' +
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
  '(\\?[;&a-z\\d%_.~+=-]*)?' +
  '(\\#[-a-z\\d_]*)?$', 'i')
  return !!urlPattern.test(urlString)
}

/**
 * checks, and validates age depnding on range
 * @param age {String}
 * @param range {Array} | {Number}
 * @returns Boolean
 */
const validateAge = (age, range) => {
  if (_isEmpty(range) && !age) {
    return false
  }

  if (!isArray(range)) {
    return age >= +range
  }

  if (isArray(range)) {
    const age1 = +range?.[0]
    const age2 = +range?.[1]

    if (age1 && !age2) {
      return age >= age1
    }

    if (age2 < age1) {
      return false
    }

    return age >= age1 && age <= age2
  }
}

const areacodeValidator = code => {
  const check = COUNTRIES.find(item => item.phoneCode === code)

  return !!(check?.phoneCode)
}

/*
* Validate state value is valid or not
* @param state
* @returns {boolean}
*/
const stateValidator = state => {
  return STATES.some(stateObj => stateObj.value === state.toUpperCase())
}

/*
 * Digits & numbers validator
 * @param number
 * @param length
 * @returns {boolean}
 */
const digitGroupValidator = (number, length) => {
  const urlPattern = new RegExp(`^[0-9]{${length}}$`)
  return !!urlPattern.test(number)
}

const validateFullname = fullname => {
  if (!fullname) {
    return false
  }

  const result = {
    valid: true
  }
  const fullnameTest = fullname.split(' ')
  let pattern = new RegExp(/^[a-zA-Z-' ]+$/) // eslint-disable-line
  let firstname = ''
  let lastname = ''

  if (fullnameTest.length < 2) {
    result.errorMessage = 'Please enter first and last name'
    result.valid = false
  } else {
    // get firstname & lastname
    lastname = fullnameTest[fullnameTest.length - 1]

    if (
      fullnameTest.length > 2 &&
      fullnameTest[fullnameTest.length - 1].length < 1
    ) {
      lastname = fullnameTest[fullnameTest.length - 2]
    }

    for (let i = 0; i < fullnameTest.length - 1; i++) {
      if (i > 0) {
        firstname += ' '
      }
      firstname += fullnameTest[i]
    }

    if (firstname.length < 2 || lastname.length < 2) {
      result.errorMessage = firstname.length < 2
        ? 'Please enter your first name and last name'
        : 'Last name under 2 characters'
      result.valid = false
    } else if (firstname === lastname) {
      result.errorMessage = 'firstname and lastname identical'
      result.valid = false
    } else if (!pattern.test(fullname)) {
      result.errorMessage = 'Please enter only alphabets'
      result.valid = false
    }
  }

  return result
}

const validateCreditCard = val => {
  const regex = /^[0-9]{13,19}$/
  if (!regex.test(val)) {
    return false
  }

  let checksum = 0 // running checksum total
  let j = 1 // takes value of 1 or 2

  // Process each digit one by one starting from the last
  for (let i = val.length - 1; i >= 0; i--) {
    let calc = 0
    // Extract the next digit and multiply by 1 or 2 on alternative digits.
    calc = Number(val.charAt(i)) * j

    // If the result is in two digits add 1 to the checksum total
    if (calc > 9) {
      checksum = checksum + 1
      calc = calc - 10
    }

    // Add the units element to the checksum total
    checksum = checksum + calc

    // Switch the value of j
    if (j === 1) {
      j = 2
    } else {
      j = 1
    }
  }

  // Check if it is divisible by 10 or not.
  return (checksum % 10) === 0 && checkCreditCardProvider(val)
}

const checkCreditCardProvider = (cardnumber, excludeArr = []) => {
  let lengthValid = false
  let prefixValid = false

  excludeArr = typeof excludeArr === 'string'
    ? [excludeArr]
    : excludeArr

  return SUPPORTED_CREDIT_CARDS
    .filter(card => !excludeArr.includes(card.name))
    .find(card => {
      const prefix = card.prefixes.split(',')

      for (let j = 0; j < prefix.length; j++) {
        const exp = new RegExp('^' + prefix[j])
        if (exp.test(cardnumber)) {
          prefixValid = true
        }
      }
      if (prefixValid) {
        const lengths = card.length.split(',').map(e => Number(e))
        lengthValid = lengths.includes(cardnumber.length)
      }

      if (lengthValid && prefixValid) {
        return card
      }
      return null
    })
}

const nameValidator = (str, model, key, firstNameKey = 'first_name', lastNameKey = 'last_name') => {
  const pattern = new RegExp(/^[a-zA-Z-' ]+$/) 

  if (
    model &&
    (!_isEmpty(model[firstNameKey])) && !_isEmpty(model[lastNameKey]) &&
    (model[firstNameKey] === model[lastNameKey])
  ) {
    return {
      status: false,
      message: 'First name and last name is identical'
    }
  } else if (!str) {
    return {
      status: false,
      message: `Please enter ${key.replace(/_/g, ' ')}`
    }
  } else if (!pattern.test(str)) {
    return {
      status: false,
      message: 'Please enter only alphabets'
    }
  } else if (str && str.length < 2) {
    return {
      status: false,
      message: 'First name under 2 characters'
    }
  }
  return {
    status: true,
    message: ''
  }
}

const companyValidator = str => {
  if (!str) {
    return {
      status: false,
      message: 'Please enter a company name'
    }
  }

  if (str && str.length < 2) {
    return {
      status: false,
      message: 'Company name under 2 characters'
    }
  }

  return {
    status: true,
    message: ''
  }
}

const bsbValidator = str => {
  const regex = /^\d{6}$/
  return regex.test(str)
}

const bankNumberValidator = str => {
  const regex = /^\d{6,10}$/
  const uniqNo = _uniq(str)
  return regex.test(str) && uniqNo.length > 2
}

const dobValidator = (date, validAge) => {
  const theDate = dayjs(date, 'DD/MM/YYYY')
  if (!theDate.isValid()) {
    return false
  }
  if (theDate > dayjs(new Date())) {
    return false
  }
  if (Array.isArray(validAge) && validAge.length === 2) {
    const today = dayjs()
    const age = today.diff(theDate, 'year')
    if (age >= validAge[0] && age <= validAge[1]) {
      return true
    }
  }
  return false
}

/*
 * Home phone number validation check(s)
 * @param phoneNumber
 * @returns {boolean}
 */
const homePhoneNumberValidator = val => {
  let result = true
  if (_isEmpty(val)) {
    return result
  }
  const pattern = /^0(2|3|5|7|8){1}[0-9]{8}$/
  if (!pattern.test(val)) {
    result = false
  }
  return result
}

const lhcAgeValidator = (value, limit) => {
  const error1 = 'Please enter age'
  const error2 = 'Invalid age range'
  if (_isEmpty(limit) && !value) {
    return error1
  }

  const reg = /^\d+$/
  if (!reg.test(value)) {
    return error1
  }

  const min = limit?.[0]
  const max = limit?.[1]

  if (value < min || value > max) {
    return error2
  }

  return true
}

const medicareValidator = value => {
  // eslint-disable-next-line
  const medicare = value.replace('/[^\d]/', '')
  let isValid = false
  const matches = medicare.match(/^([2-6]\d{7})(\d)/)

  if (medicare.length === 10 && matches) {
    const base = matches[1]
    const checkDigit = matches[2]
    const weights = [1, 3, 7, 9, 1, 3, 7, 9]

    let sum = 0
    for (let i = 0; i < weights.length; i++) {
      sum += parseInt(base[i]) * weights[i]
    }

    isValid = sum % 10 === parseInt(checkDigit)
  }
  return isValid
}

const cardExpDate = cardDate => {
  const val = cardDate.split('/')
  const currDate = dayjs().format('MM/YYYY').split('/')
  if (val.length !== 2 || val[1].length !== 4) {
    return false
  }

  return (val[0] > currDate[0] && currDate[1] === val[1]) || val[1] > currDate[1]
}

const mediCardExpDate = cardDate => {
  const val = cardDate.split('/')
  const currDate = dayjs().format('MM/YYYY').split('/')
  if (val.length !== 2 || val[1].length !== 4) {
    return false
  }

  return (val[0] >= currDate[0] && currDate[1] === val[1]) || val[1] > currDate[1]
}

const cvvValidator = str => {
  const regex = /^\d{3,4}$/
  return regex.test(str)
}

const dateRangeValidator = (date, limit) => {
  const theDate = dayjs(date, 'DD/MM/YYYY')
  if (!theDate.isValid()) {
    return false
  }

  if (limit === 'within 1 month') {
    const today = dayjs()
    if (theDate.isAfter(today) && theDate.diff(today, 'month') < 1) {
      return true
    }
  }

  if (limit === 'in future') {
    const today = dayjs()
    if (theDate.isAfter(today)) {
      return true
    }
  }

  if (limit === 'after 15 days and within 1 month') {
    const today = dayjs()
    const after = dayjs().add(15, 'day')
    if ((theDate.isAfter(after) || theDate.isSame(after, 'day')) && theDate.diff(today, 'month') < 1) {
      return true
    }
  }
  return false
}

const optionalFutureDate = date => {
  if (!date || date.trim() === '') {
    return true
  }
  const theDate = dayjs(date, 'DD/MM/YYYY')
  if (!theDate.isValid()) {
    return false
  }
  const today = dayjs()
  if (theDate.isAfter(today)) {
    return true
  }
  return false
}

const childrenDobValidator = (date, validAge) => {
  const error1 = 'Please enter valid date of birth'
  const error2 = 'Dependant age cannot be more then 31 years old'
  const theDate = dayjs(date, 'DD/MM/YYYY')
  if (!theDate.isValid()) {
    return error1
  }
  if (theDate > dayjs(new Date())) {
    return error1
  }
  if (Array.isArray(validAge) && validAge.length === 2) {
    const today = dayjs()
    const age = today.diff(theDate, 'year')
    if (age < validAge[0] || age > validAge[1]) {
      return error2
    }
  }
  return true
}

export {
  emailValidator,
  textValidator,
  passwordValidator,
  numberValidator,
  phoneNumberValidator,
  postCodeValidator,
  arrayObjectPropValidator,
  isValidUrl,
  validateAge,
  areacodeValidator,
  stateValidator,
  objectKeysValidator,
  digitGroupValidator,
  validateFullname,
  validateCreditCard,
  checkCreditCardProvider,
  nameValidator,
  companyValidator,
  bsbValidator,
  bankNumberValidator,
  dobValidator,
  homePhoneNumberValidator,
  lhcAgeValidator,
  medicareValidator,
  cardExpDate,
  mediCardExpDate,
  cvvValidator,
  dateRangeValidator,
  optionalFutureDate,
  childrenDobValidator
}
